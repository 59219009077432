import * as React from "react"
import Layout from "../layout/layout";
import OpenConfigurator from "../components/general/open-configurator";
import SEO from "../components/seo";

const ShippingPage = () => {
  return (
    <Layout>
      <SEO title={"Wysyłka"} />
      <h1>Wysyłka</h1>
      <p>Po otrzymaniu
        przez nas Twojej specyfikacji, indywidualnie wyceniamy koszt wysyłki, a następnie odsyłamy fakturę pro forma.
        Po otrzymaniu płatności rozpoczynamy produkcję.
      </p>
      <p>
        Wysyłamy kurierem DHL. W przypadku większych zamówień możemy zapewnić własy transport.
      </p>
      <OpenConfigurator />
    </Layout>
  )
}

export default ShippingPage
